<template>
    <v-dialog
        ref="dialog"
        v-model="modal"
        :persistent="persistent"
        :width="width"
        @click:outside="cancel">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-if="!hideButton"
                @click.stop="openDialog"
                :block="block"
                :disabled="disabled"
                :large="large"
                :loading="loading"
                :light="light"
                :icon="icon != null && text == null && !isButton"
                :small="small"
                :text="text != null && !isButton"
                v-bind="attrs"
                v-on="on"
                :class="buttonClass"
                :title="title">
                <slot name="buttonSlot" v-bind:item="selectedItem">
                    <v-icon v-if="icon != null" :large="large" :left="icon != null && text != null" :small="small">{{ icon }}</v-icon>
                    <span v-if="text != null">{{ text }}</span>
                </slot>
            </v-btn>
        </template>
        <v-card
            :flat="flat"
            :min-height="height"
            :loading="loading">
            <v-card-title>{{ label }}</v-card-title>
            <v-card-text>
                <v-slide-x-transition>
                    <v-text-field
                        v-if="canSearch"
                        flat
                        dense
                        hide-details
                        hide-no-data
                        solo-inverted
                        single-line
                        prepend-inner-icon="mdi-close"
                        append-icon="mdi-magnify"
                        label="Find"
                        v-model="searchInput"
                        @click:prepend-inner="searchInput = null" />
                </v-slide-x-transition>
                <v-list 
                    v-if="isLengthyArray(filteredItems)" 
                    class="overflow-y-auto" 
                    :height="height">
                    <v-list-item-group
                        v-model="selectedItem"
                        :multiple="multiple"
                        :active-class="activeClass"
                        @change="update">
                        <template v-for="(fItem, index) in filteredItems">
                            <v-list-item
                                :key="index"
                                :value="fItem">
                                <slot v-bind:item="fItem">
                                    <div>{{ listItemText ? nestVal(fItem, listItemText) : fItem }}</div>
                                </slot>
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
            <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>
        </v-card>
    </v-dialog>
</template>

<script>
import { nestedValue } from '~helpers';

export default {
    name: 'BT-Select-Dialog',
    data: function() {
        return {
            asyncItems: [],
            item: null,
            modal: false,
            loading: false,
            searchInput: null,
            selectedItem: null,
            errorMessage: null,
            showError: false,
        }
    },
    props: {
        activeClass: {
            type: String,
            default: 'primary'
        },
        block: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: null //'primary--text'
        },
        canCancel: {
            type: Boolean,
            default: false
        },
        canSearch: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false,
        },
        getOnOpen: {
            type: Function,
            default: null
        },
        getOnOpenAsync: {
            type: Function,
            default: null
        },
        height: {
            type: String,
            default: 'auto'
        },
        hideButton: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'mdi-plus'
        },
        isButton: {
            type: Boolean,
            default: false
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        light: {
            type: Boolean,
            default: false
        },
        listItemText: {
            type: String,
            default: 'text'
        },
        listItemValue: {
            type: String,
            default: 'value'
        },
        multiple: {
            type: Boolean,
            default: false
        },
        navigation: {
            type: String,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        // origin: {
        //     type: String,
        //     default: 'center center'
        // },
        params: {
            type: Object,
            default: null // () => { return { }}
        },
        persistent: {
            type: Boolean,
            default: false
        },
        // queryProp: {
        //     type: String,
        //     default: null
        // },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        searchProps: {
            type: Array,
            default: null
        },
        show: {
            type: Boolean,
            default: false
        },
        showToggle: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        value: null,
        width: {
            type: String,
            default: 'auto'
        }
    },
    watch: {
        refreshToggle: function() {
            this.pullItems(true);
        },
        show: function(val) {
            this.modal = val;
            if (this.modal) {
                this.pullItems();
            }
        },
        showToggle() {
            if (this.modal === false) {
                this.pullItems();
            }
            
            this.modal = true;
        },
        value: function(val) {
            if (val) {
                this.item = val;
            }
        },
    },
    async mounted() {
        if (this.value) {
            this.item = this.value;
        }

        this.modal = this.show;

        if (this.modal) {
            this.pullItems();
        }
    },   
    computed: {
        filteredItems() {
            var l = this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
            
            if (this.canSearch && this.searchInput != null) {
                l = l.filter(x => this.hasSearch(x, this.searchInput, this.searchableProperties));
            }

            return l;
        },
        searchableProperties() {
            var l = []

            if (this.listItemText != null) {
                l.push(this.listItemText);
            }

            if (this.isLengthyArray(this.searchProps)) {
                l = [...new Set(l.concat(this.searchProps))];
            }

            return l;
        },
    },
    methods: {   
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        startLoading() {
            this.loading = true;
            this.$forceUpdate();
        },
        endLoading() {
            this.loading = false;
            this.$forceUpdate();
        },
        cancel() {
            if (this.canCancel) {
                this.$emit('cancel');
                this.modal = false;
            }
        }, 
        findSelectedItem() {
            if (!this.selectedItem) {
                if (this.returnObject || this.item == null) {
                    return;
                }
                else if (this.listItemValue) {
                    this.selectedItem = this.asyncItems.find(x => x[this.listItemValue] == this.item);
                }
            }
        },
        nestVal(item, path) {
            return nestedValue(item, path);
        },
        async openDialog() {
            if (this.getOnOpenAsync != null) {
                try {
                    this.startLoading();
                    this.asyncItems = await this.getOnOpenAsync();
                }
                catch (err) {
                    this.formError(err);
                }
                finally {
                    this.endLoading();
                }
            }
            else if (this.getOnOpen != null) {
                this.asyncItems = this.getOnOpen();
            }
            else {
                await this.pullItems();
            }
        },
        async pullItems(refresh = false) {
            if (this.items != null) {
                this.asyncItems = this.items;
                return;
            }

            if (this.navigation == null) {
                return;
            }

            try {
                this.startLoading();
                var res = null;
                if (this.isSingle) {
                    res = await this.$BlitzIt.store.get(this.navigation, this.itemID, this.params, refresh);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, this.params, refresh);
                }
                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, refresh);
                }
                else {
                    this.asyncItems = res;
                }

                this.findSelectedItem();
            }
            catch (err) {
                console.log(err);
                this.formError(err);
            }
            finally {
                this.endLoading();
            }
        },
        update() {
            var v = null;
            
            if (this.selectedItem == null) {
                console.log('returning nothing');
                return;
            }
            
            if (this.returnObject) {
                v = this.selectedItem;
            }
            else if (this.listItemValue) {
                v = this.selectedItem[this.listItemValue];
            }
            else {
                v = this.selectedItem;
            }

            this.$emit('input', v);
            this.$emit('change', v);

            this.selectedItem = null;
            this.modal = false;

        }
    }
}
</script>